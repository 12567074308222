<template>
  <div>
    <filter-params
      class="mb-8"
      v-model="computedFilter"
      :auto-filter-type="targetDocTy"
      :readonly="readonly"
    />
    <br />
    <agg
      :document.sync="computedAgg"
      :readonly="readonly"
      :target-doc-ty="targetDocTy"
    />
  </div>
</template>
<script>
export default {
  components: {
    FilterParams: () => import('../filter-params/filter-params'),
    Agg: () => import('./agg')
  },
  computed: {
    computedAgg: {
      get () {
        return this.dataAgg
      },
      set (v) {
        this.dataAgg = v
        this.emitDocument()
      }
    },
    computedFilter: {
      get () {
        return this.dataFilter
      },
      set (v) {
        this.dataFilter = v
        this.emitDocument()
      }
    }
  },
  data () {
    return {
      dataAgg: {},
      dataFilter: {}
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      const doc = this.lodash.cloneDeep({ agg: this.dataAgg })
      if (this.dataFilter) {
        this.$set(doc, 'filter', this.lodash.cloneDeep(this.dataFilter))
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', doc)
      }
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        const iter = this.lodash.cloneDeep(v)
        this.dataAgg = iter?.agg
        this.dataFilter = iter?.filter

        this.emitDocument()
      },
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped></style>
